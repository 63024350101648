import { ginzaxiaomaApiRequest } from 'apis';
import { UnwrapPromise } from 'types/base';

const SmsApi = {
  getCouponList: (params: { pageNum: number; pageSize: number }) => {
    return ginzaxiaomaApiRequest.get<{
      total: number;
      list: {
        id: number;
        type: number;
        name: string;
        platform: number;
        minPoint: number;
        amount: number;
        startTime?: string;
        endTime?: string;
        note?: string;
        code?: string;
      }[];
    }>('/admin/sms/coupon-list', {
      params,
    });
  },
  getCouponHistoryList: (params: {
    pageNum: number;
    pageSize: number;
    couponId?: number;
  }) => {
    return ginzaxiaomaApiRequest.get<{
      total: number;
      list: {
        id: number;
        couponId: number;
        memberId?: number;
        couponCode?: string;
        useStatus: number;
        amount?: number;
        createTime?: string;
        useTime?: string;
        orderSn?: string;
        orderId?: number;
        smsCoupon: SmsCoupon;
        umsMember?: {
          id: number;
          email: string;
          firstName?: string;
          lastName?: string;
        };
      }[];
    }>('/admin/sms/coupon-history-list', {
      params,
    });
  },
};

export type SmsCoupon = UnwrapPromise<
  ReturnType<typeof SmsApi.getCouponList>
>['list'][number];

export type SmsCouponHistory = UnwrapPromise<
  ReturnType<typeof SmsApi.getCouponHistoryList>
>['list'][number];

export default SmsApi;
